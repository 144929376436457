import {
  GET_UJIAN_LIST,
  GET_UJIAN_SINGLE,
  ADD_UJIAN,
  EDIT_UJIAN,
  SET_CURRENT_UJIAN,
  CLEAR_CURRENT_UJIAN,
  TOGGLE_ACTIVATE_UJIAN,
  DUPLICATE_UJIAN,
  GET_UJIAN_ACTIVE,
  GET_DETAIL_UJIAN_AND_PESERTA,
  GET_HASIL_UJIAN,
  DELETE_UJIAN
} from './types'

const initialState = {
  data_ujian: [],
  monitoring_ujian_list: {},
  monitoring_ujian_detail: null,
  jawaban_detail: [],
  single_ujian: {},
  current: null
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_UJIAN_LIST:
    case DUPLICATE_UJIAN:
      return {
        ...state,
        data_ujian: payload
      }
    case GET_UJIAN_SINGLE:
      return {
        ...state,
        single_ujian: payload
      }
    case GET_UJIAN_ACTIVE:
      return {
        ...state,
        monitoring_ujian_list: payload
      }
    case GET_DETAIL_UJIAN_AND_PESERTA:
      return {
        ...state,
        monitoring_ujian_detail: payload
      }
    case GET_HASIL_UJIAN:
      return {
        ...state,
        jawaban_detail: payload
      }
    case ADD_UJIAN:
      return {
        ...state,
        data_ujian: [...state.data_ujian, payload]
      }
    case TOGGLE_ACTIVATE_UJIAN:
    case EDIT_UJIAN:
      return {
        ...state,
        data_ujian: state.data_ujian.map((ujian) => (ujian.id === payload.id ? payload : ujian))
      }
    case DELETE_UJIAN:
      return {
        ...state,
        data_ujian: payload
      }
    case SET_CURRENT_UJIAN:
      return {
        ...state,
        current: payload
      }
    case CLEAR_CURRENT_UJIAN:
      return {
        ...state,
        current: null
      }
    default:
      return state
  }
}
