import { combineReducers } from "redux";
import auth from "./auth/reducers";
import support from "./support/reducers";
import event from "./event/reducers";
import payment from "./payment/reducers";
import ujian from "./ujian/reducers";
import soal from "./soal/reducers";

export default combineReducers({
  auth,
  support,
  event,
  payment,
  ujian,
  soal,
});
