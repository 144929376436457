import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CContainer, CAlert, CRow, CCol } from "@coreui/react";

const Alerts = () => {
  const alerts = useSelector(state => state.support.alerts)

  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <AlertArea key={alert.id}>
        <CContainer className="pt-5">
          <CRow alignHorizontal="center">
            <CCol md={4}>
              <CAlert color={alert.type} className="text-center">
                <i className="fas fa-info-circle mr-2" />
                {alert.msg}
              </CAlert>
            </CCol>
          </CRow>
        </CContainer>
      </AlertArea>
    ))
  );
};

const AlertArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
`;

export default Alerts;
