export default async function client(endpoint, { body, headers: customHeaders, ...customConfig } = {}) {
  const config = {
    method: body ? 'POST' : 'GET',
    body: body,
    headers: customHeaders,
    ...customConfig
  }

  return window.fetch(endpoint, config).then(async (response) => {
    if (response.status === 401) {
      // refresh the page for them
      window.location.assign(window.location)
      return Promise.reject({ message: 'Please re-authenticate.' })
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
