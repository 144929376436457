import { GET_UNCONFIRMED_PAYMENT, GET_PAYMENT, TOGGLE_VERIFIED } from './types'

const initialState = {
  unconfirmed_amt: 0,
  unconfirmed: [],
  paid: []
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PAYMENT:
      return {
        ...state,
        paid: payload
      }
    case TOGGLE_VERIFIED:
      return {
        ...state,
        paid: state.paid.map((item) => (item.id === payload.id ? { ...item, ditindaklanjuti: !item.ditindaklanjuti } : item))
      }
    case GET_UNCONFIRMED_PAYMENT:
      return {
        ...state,
        unconfirmed: payload,
        unconfirmed_amt: payload.length
      }
    default:
      return state
  }
}
