import { USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, RESET_EMAIL_STATUS } from './types'

const initialState = {
  token: localStorage.getItem('__depas_admin_auth__'),
  isAuthenticated: null,
  isLoading: false,
  user: null
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('__depas_admin_auth__', payload)
      return {
        ...state,
        token: payload
      }
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem('__depas_admin_auth__')
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      }
    case RESET_EMAIL_STATUS:
      return {
        ...state,
        email_active: null
      }
    default:
      return state
  }
}
