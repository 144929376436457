import { GET_OLIMPIADE_PARTICIPANT, GET_UDSRC_PARTICIPANT, GET_SEMINAR_PARTICIPANT, GET_EVENT_LIST, TOGGLE_CONFIRMATION } from './types'

const initialState = {
  peserta_olimpiade: [],
  peserta_seminar: [],
  peserta_udsrc: [],
  event: []
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_EVENT_LIST:
      return {
        ...state,
        event: payload
      }
    case GET_OLIMPIADE_PARTICIPANT:
      return {
        ...state,
        peserta_olimpiade: payload
      }
    case GET_SEMINAR_PARTICIPANT:
      return {
        ...state,
        peserta_seminar: payload
      }
    case GET_UDSRC_PARTICIPANT:
      return {
        ...state,
        peserta_udsrc: payload
      }
    case TOGGLE_CONFIRMATION:
      return {
        ...state,
        peserta_olimpiade:
          payload.event === 3
            ? state.peserta_olimpiade.map((item) =>
                item.id === payload.id ? { ...item, status_partisipasi: !item.status_partisipasi } : item
              )
            : state.peserta_olimpiade,
        peserta_seminar:
          payload.event === 1
            ? state.peserta_seminar.map((item) =>
                item.id === payload.id ? { ...item, status_partisipasi: !item.status_partisipasi } : item
              )
            : state.peserta_seminar,
        peserta_udsrc:
          payload.event === 2
            ? state.peserta_udsrc.map((item) => (item.id === payload.id ? { ...item, status_partisipasi: !item.status_partisipasi } : item))
            : state.peserta_udsrc
      }
    default:
      return state
  }
}
