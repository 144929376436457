import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/common/PrivateRoute'
import { loadUser } from './config/redux/auth/actions'
import Alerts from './components/layouts/Alerts'
import { useDispatch } from 'react-redux'

import './scss/style.scss'

// Containers
const DefaultLayout = lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = lazy(() => import('./components/pages/Login'))
const TinjauUjian = lazy(() => import('./components/pages/TinjauUjian'))

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUser())

  }, [dispatch])

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  return (
    <Router>
      <Alerts />
      <Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/tinjau/ujian/:id" name="Tinjau Ujian" component={TinjauUjian} />
          <PrivateRoute path="/" name="Beranda" component={DefaultLayout} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
