import { v4 as uuid } from "uuid";
import { GET_ERRORS, SET_ALERT, REMOVE_ALERT } from "./types";

// RETURN ERRORS
export const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status },
  };
};

// SET ALERT
export const setAlert = (msg, type, timeout = 2000) => (dispatch) => {
  const id = uuid();

  dispatch({
    type: SET_ALERT,
    payload: { msg, type, id },
  });

  // Remove Alert
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};
