import { returnErrors } from '../support/actions'
import { API_URL, http } from '../../../utils'
import { USER_LOADING, USER_LOADED, LOGIN_FAIL, LOGIN_SUCCESS, AUTH_ERROR, RESET_EMAIL_STATUS, LOGOUT_SUCCESS } from './types'

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch) => {
  // User Loading
  dispatch({ type: USER_LOADING })

  return http(`${API_URL}/panitia`, { headers: tokenConfig() }).then(
    (res) => {
      if (res.error) {
        dispatch({ type: AUTH_ERROR })
        dispatch(returnErrors({ msg: res.message, status: 404 }))
      } else {
        dispatch({
          type: USER_LOADED,
          payload: res
        })
      }
    },
    () => console.log('Something went wrong')
  )
}

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Request Body
  const data = JSON.stringify({ email, password })

  try {
    const response = http(`${API_URL}/panitia/login`, {
      method: 'post',
      body: data,
      headers: tokenConfig()
    })

    response.then((res) => {
      if (res.error) {
        dispatch({ type: LOGIN_FAIL })
        dispatch(returnErrors({ msg: res.message, status: 404 }))
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        })
        dispatch(loadUser())
      }
    })
  } catch (err) {
    throw new Error(err.response)
  }
}

// LOGOUT USER
export const logout = () => (dispatch) => {
  dispatch({ type: RESET_EMAIL_STATUS })
  dispatch({ type: LOGOUT_SUCCESS })
}

// Setup config with token - helper function
export const tokenConfig = (contentType = 'application/json') => {
  const token = localStorage.getItem('__depas_admin_auth__')
  const config = {
    'Content-Type': contentType === 'undefined' ? undefined : contentType,
    Authorization: token ? `Bearer ${token}` : undefined
  }

  return config
}
