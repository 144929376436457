export const GET_UJIAN_LIST = 'GET_UJIAN_LIST'
export const GET_UJIAN_SINGLE = 'GET_UJIAN_SINGLE'
export const GET_UJIAN_ACTIVE = 'GET_UJIAN_ACTIVE'
export const GET_DETAIL_UJIAN_AND_PESERTA = 'GET_DETAIL_UJIAN_AND_PESERTA'
export const GET_HASIL_UJIAN = 'GET_HASIL_UJIAN'
export const EXPORT_DATA = 'EXPORT_DATA'
export const RESET_UJIAN = 'RESET_UJIAN'
export const ADD_UJIAN = 'ADD_UJIAN'
export const EDIT_UJIAN = 'EDIT_UJIAN'
export const SET_CURRENT_UJIAN = 'SET_CURRENT_UJIAN'
export const CLEAR_CURRENT_UJIAN = 'CLEAR_CURRENT_UJIAN'
export const TOGGLE_ACTIVATE_UJIAN = 'TOGGLE_ACTIVATE_UJIAN'
export const DELETE_UJIAN = 'DELETE_UJIAN'
export const DUPLICATE_UJIAN = 'DUPLICATE_UJIAN'
