import { GET_ERRORS, SET_ALERT, REMOVE_ALERT, TOGGLE_SIDEBAR } from './types'

const initialState = {
  msg: null,
  status: null,
  alerts: [],
  sidebarShow: 'responsive'
}

export default function (state = initialState, { type, payload, ...rest }) {
  switch (type) {
    case GET_ERRORS:
      return {
        ...state,
        msg: payload.msg,
        status: payload.status
      }
    case SET_ALERT:
      return { ...state, alerts: [payload] }
    case REMOVE_ALERT:
      return {
        ...state,
        msg: null,
        alerts: state.alerts.filter((alert) => alert.id !== payload)
      }
    case TOGGLE_SIDEBAR:
      return { ...state, ...rest }
    default:
      return state
  }
}
