// Convertion to IDR format
export const idrFormat = (amount) => {
  let amt = amount.toString()
  let less = amt.length % 3
  let idr = amt.substr(0, less)
  let thousand = amt.substr(less).match(/\d{3}/g)

  if (thousand) {
    const separator = less ? '.' : ''
    idr += separator + thousand.join('.')
  }

  return idr
}

// DATE FORMAT CONVERTER
export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('/')
}

// Convertion to USD format
export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
