import { GET_SOAL_LIST, GET_SOAL_SINGLE, ADD_SOAL, EDIT_SOAL, SET_CURRENT_SOAL, CLEAR_CURRENT_SOAL } from './types'

const initialState = {
  soal_list: [],
  soal_single: {},
  current: null
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_SOAL_LIST:
      return {
        ...state,
        soal_list: payload
      }
    case GET_SOAL_SINGLE:
      return {
        ...state,
        soal_single: payload
      }
    case ADD_SOAL:
      return {
        ...state,
        soal_list: [...state.soal_list, payload]
      }
    case EDIT_SOAL:
      return {
        ...state,
        soal_list: state.soal_list.map((soal) => (soal.id === payload.id ? payload : soal))
      }
    case SET_CURRENT_SOAL:
      return {
        ...state,
        current: payload
      }
    case CLEAR_CURRENT_SOAL:
      return {
        ...state,
        current: null
      }
    default:
      return state
  }
}
